import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import BaseBanner from 'components/banners/base-banner';

const AppBannerOne = () => {
  const data = useStaticQuery(graphql`
     query {
       lg: file(relativePath: { eq: "in-app/one-xl.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, formats: [AUTO,WEBP], placeholder:TRACED_SVG)
        }
       }
       md: file(relativePath: { eq: "in-app/one-md.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, formats: [AUTO,WEBP], placeholder:TRACED_SVG)
        }
       }
       sm: file(relativePath: { eq: "in-app/one-md.png" }) {
         childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, formats: [AUTO,WEBP], placeholder:TRACED_SVG)
        }
       }
       xs: file(relativePath: { eq: "in-app/one-xs.png" }) {
         childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, formats: [AUTO,WEBP], placeholder:TRACED_SVG)
        }
       }
     }
  `);

  return (
    <BaseBanner data={data}/>
  );
};

export default AppBannerOne;
